import React from "react";
import Logo from "./Logo";
import Navigation from "./Navigation";
import { Box } from 'grommet';
import { Link } from 'react-router-dom';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.tagLine = props.tagLine;
        this.includeNav = props.includeNav;
        this.navType = props.navType;


    }
    render() {
        return (
            <Box tag="header" background={"white"} pad={{ left: "0", right: "24px", top: "24px", bottom: "24px" }} className="fer-header" direction={"row"} height={"60px"} align="center" justify="between">
                <Box direction={"row"}>
                    <Link to="/main">
                    {/* <a href="/main"> */}
                        <Logo />
                    {/* </a> */}
                    </Link>
                </Box>
                {this.tagLine ? <h2><img width="400px" src={require(".././assets/Quote_Navigation_2023.svg")} alt="Let faith oust fact; let fancy oust memory..." /></h2>
                    : ''}
                {this.includeNav ? <Navigation className="fer-navigation" navType={this.navType} navClickHandler={this.props.navClickHandler} graphNav={this.props.graphNav} /> : ''}
            </Box>
        )
    }
}
export default Header;