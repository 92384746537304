import React from "react";
import "./../Chart.css";
import "./../Spinner.css";
import { Heading } from "grommet";
import arrayMove from "array-move";
import {_findIndex} from "../Utils";
const buildChartData = graphData => {
  if (!graphData) {
    return;
  }

  const sortedItems = graphData.items.sort((a, b) => a.value - b.value);
  // Sort by biggest first so the SVG groups get layered properly
  const biggestItem = sortedItems[1];

  //Get relative percentage of sorted circles from the the biggest item
  return sortedItems.map((item, i) => {
    item.proportionOfBiggest = (item.value / biggestItem.value) * 100;
    item.isBiggestItem = i === 0; // The first element in the array
    return item;
  });
};
class HalfCircleChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      items: []
    };

    this.graphData = props.graphData;
  }

  componentDidMount() {
    this.setState({
      isLoaded: true
    });
  }

  stripSpaces(text) {
    return text.replace(/\s/g, "");
  }

  render() {
    const { error, isLoaded } = this.state;
    const { showValues, showKey } = this.props;
    let items = buildChartData(this.props.graphData);

    if (items) {
      items = arrayMove(
        items,
        _findIndex(items,"Gross tonnage"),
        0
      );
    }

    if (!items) {
      return '';
    }
    

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="spinner-icon" role="presentation"></div>;
    } else {
      return (
        <div className="chart-wrapper half-radial">
          {showKey && (
            <Heading
              className="chart-year current"
              color={"fBlack"}
              level="3"
              margin="none"
              size="14px"
            >
              2023
            </Heading>
          )}

          {showValues && (
            <ul className="chart-values current">
              {items.map((item, i) => (
                <li
                  id={`${item.value}%`}
                  className="chart-key-item"
                  key={i}
                  style={{ color: item.color }}
                >
                  {item.value}%
                </li>
              ))}
            </ul>
          )}

          <svg className="chart-svg half-radial" viewBox="0 0 100 100">
            <g className="half-radial-path-group">
              {items.map((item, i) => (
                <g
                  className="half-radial-path"
                  aria-labelledby={`${item.title} ${item.value}%`}
                  key={i}
                >
                  {i === 0 ? (
                    <path
                      d={` M${100 + item.proportionOfBiggest / 2}, 50 a1, 1 0 0, 0 -${item.proportionOfBiggest} 0`}
                      fill={item.color}
                    />
                  ) : (
                      <path d={` M${100 - item.proportionOfBiggest / 2}, 50 a1, 1 0 0, 0 ${item.proportionOfBiggest}, 0`} fill={item.color} />
                    )}
                </g>
              ))}
            </g>
          </svg>

          {showKey && (
            <ul className="chart-key">
              {items.map((item, i) => (
                <li
                  id={this.stripSpaces(item.title)}
                  className="chart-key-item"
                  key={i}
                >
                  <svg className="chart-key-svg" viewBox="0 0 10 10">
                    <circle cx="5" cy="5" r="3" fill={item.color} />
                  </svg>
                  {item.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    }
  }
}

export default HalfCircleChart;
