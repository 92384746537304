import React from 'react';
import { Box } from 'grommet';
import BodyClassSetter from '../Elements/BodyClassSetter';

class Splash extends React.Component {

  render() {
    return (
      <BodyClassSetter customClass={'splash-wrapper'}>
        <Box className="splash animated" align={"center"} justify={"between"}>
          <Box align={"center"}>

            <h1>
              <img className="splash-logo" src={require(".././assets/home/logo-bottom/LocktonMarine_RGB_NEG_2023.svg")} alt="P.L. Ferrari" title={"P.L. Ferrari"} />
            </h1>

            <h2 className="splash-title">
              <img width="300" src={require(".././assets/home/logo-bottom/Review2023_Splash2.svg")} alt="P&amp;I Market Review 2023" />
            </h2>

          </Box>

          <Box align="center" justify="center">
            <h3>

              <img width="700px" height="auto" style={{marginBottom:100}} src={require(".././assets/home/logo-bottom/Quote_Splash_2023.svg")} alt="Let faith oust fact; let fancy oust memory..." />
            </h3>
            <div class="bottom-logo">
              <img src={require(".././assets/home/logo-bottom/Lockup_Omni_23.svg")} alt="Lockup Omni" />
              <img src={require(".././assets/home/logo-bottom/Lockup_PLF_23.svg")} alt="Lockup PLF" />
              <img src={require(".././assets/home/logo-bottom/Lockup_Edge_23.svg")} alt="Lockup Edge" />
            </div>
          </Box>
        </Box>
      </BodyClassSetter >
    )
  }
}

export default (Splash);