import React from 'react';
import { withRouter } from 'react-router-dom';
import Logo from "../Elements/Logo";
import {Text} from "grommet/es6";
import {Download} from "grommet-icons/es6";
import {isBrowserSupported} from "../Utils";

class UnsupportedBrowser extends React.Component {
	componentDidMount() {
		if(isBrowserSupported()){
			this.props.history.push('/main');
		}
	}

	render() {
		return (
			<div id="fer-unsupportedBrowser" className="splash fer-unsupportedBrowser">
				<div>
					<Logo/>
				</div>
				<p>
					The P&amp;I Market Review has been optimised for modern browsers
					<br/>
					Please use a browser like Chrome, Firefox, Edge, Safari
					<br/>
					or
					<br/>
				<a className="error-link mobile"
				   href={require("../assets/download/P&I_Market Review_2023_January2024Edition.pdf")} download>
					<Text size="14px">Click here to download the P&amp;I Market Review PDF</Text>
					<Download className="error-icon" size={"14px"} fill={"fBlack"}/>
				</a>
				</p>
			</div>
		)
	}
}

export default withRouter(UnsupportedBrowser);