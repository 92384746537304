import React from "react";
import { Grommet, CheckBox, Box, Text, Button } from 'grommet';
import { StatusGoodSmall, LinkPrevious, LinkNext } from "grommet-icons";
import '../FilterButton.css';
import {
	resetUnavailableColours,
	selectAllClubs,
	resetColourClasses,
	getFirstAvailableColour,
	returnUnavailableColour, getGraphCount, getGraphById
} from "../Utils";
import ClearButton from "./ClearButton";


class ClubFilterButton extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			clubs: [],
			isOverview: false,
			checkedCount: 0,
			availableColourClasses: [],
			colourToUse: "initial",
			addedCount: 1
		}
		this.maxCheckedAllowed = 3;
		this.state.clubs = selectAllClubs();
		this.state.clubs.unshift({ name: "Overview", checked: true, id: 0, colour: '#ee2c3c', shouldShowKey: false, addedOrder: 0 });
		this.state.availableColourClasses = resetColourClasses();
		this.nextPrevClickHandler = props.nextPrevClickHandler;
		this.currentGraph = props.currentGraph;
		this.graphKeyMouseEnterHandler = props.graphKeyMouseEnterHandler;
		this.graphKeyMouseLeaveHandler = props.graphKeyMouseEnterHandler;
	}
	componentDidMount() {
		this.setOverview();
	}
	getAllCheckedClubsLength() {

		return this.state.clubs.filter(checked => checked.checked === true).length;
	}
	getAllCheckedClubs() {
		return this.state.clubs.filter(checked => checked.checked === true);
	}
	setChecked(index, event, club) {

		var checkedCount = this.getAllCheckedClubsLength();
		var checked = event.target.checked;
		if (checkedCount > this.maxCheckedAllowed) {
			this.resetFilter();
			checkedCount = this.getAllCheckedClubsLength();
		}
		if ((checkedCount < this.maxCheckedAllowed && checked) || !checked) {
			if (!checked) {
				this.changeColorForSelected(event.target, club, false);
			}
			if ((index === 0 && club.name === "Overview" && checked)
				|| (index !== 0 && checkedCount <= 1 && !checked)) {
				this.resetFilter();
				this.setOverview();
				this.props.filterGetter(this.state);
				return;
			}
			if (index !== 0 && checked && this.state.clubs[0].checked) {
				this.state.clubs[0].checked = false;
			}
			if (checkedCount <= 1 && index === 0 && !checked) {
				checked = true;
				return;
			}
			this.state.clubs[index].checked = checked
			if (this.getAllCheckedClubsLength() === 1) {
				this.state.clubs[index].shouldShowKey = true;
			}
			this.addRemoveFilter(checked, index);
			this.changeColorForSelected(event.target, club, checked);
		} else if ((checkedCount >= this.maxCheckedAllowed && checked && index === 0) || (checkedCount <= this.maxCheckedAllowed && !checkedCount && index === 0)) {
			this.resetFilter();
			this.setOverview();
		}
		this.props.filterGetter(this.state);

		return;
	}
	resetFilter() {
		this.state.clubs.map((el, i) => {
			this.state.clubs[i].checked = false;
			this.state.clubs[i].shouldShowKey = false;
			return el;
		})
	}
	setOverview(overviewActive) {
		var overviewState = true;
		if (typeof overviewActive != 'undefined') {
			overviewState = overviewActive;
		}
		//this.state.filtered = this.selectAllClubs();
		/*        this.state.clubs.forEach(club =>(
								club.checked = true
						))*/
		this.state.clubs[0].checked = true;
		this.state.isOverview = overviewState;
		this.state.checkedCount = 0;
		this.state.addedCount = 0;
		this.resetHiglight();
	}
	addRemoveFilter(isChecked, index) {
		if (isChecked) {
			this.state.clubs[index].checked = isChecked;
			this.state.isOverview = false;
			this.state.checkedCount = this.getAllCheckedClubsLength();
		}

	}
	clearFilterClickHandler = () => {
		this.resetFilter();
		this.setOverview();
		this.state.checkedCount = 0;
		this.props.filterGetter(this.state);
	}

	resetHiglight() {
		const className = "filterPanel-checkbox-wrapper";
		var filterButttons = document.getElementsByClassName(className);
		for (var i = 0; i < filterButttons.length; i++) {
			filterButttons[i].style.backgroundColor = 'inherit';
		}
		resetUnavailableColours(this.state.availableColourClasses);
	}
	changeColorForSelected(target, club, changed) {
		var filterButtonToChange = target.parentNode.parentNode.parentNode;
		if (changed) {
			var colour = getFirstAvailableColour(this.state.availableColourClasses, club);
			club.colour = colour;
			filterButtonToChange.style.backgroundColor = colour;
			this.state.clubs[club.id].addedOrder = this.state.addedCount;
			this.state.addedCount++;
		} else {
			returnUnavailableColour(this.state.availableColourClasses, club);
			filterButtonToChange.style.backgroundColor = 'initial';
		}
	}
	getPrevNextGraphName(action) {
		var graphsLength = getGraphCount();
		var currGraphId = this.currentGraph[0].id;
		var idToUse = currGraphId;
		if (action === "next") {
			idToUse = idToUse + 1;
			if (idToUse > graphsLength) {
				idToUse = 0;
			}
		} else {
			idToUse = idToUse - 1;
			if (idToUse < 1) {
				idToUse = graphsLength;
			}
		}
		var graph = getGraphById(idToUse);

		return graph[0].title;
	}

	render() {
		var clubs = this.state.clubs;
		const theme = {
			global: {
				font: {
					size: "12px"
				},
				focus: {
					border: {
						color: "rgba(0,0,0,0.15)",
						outline: "rgba(0,0,0,0.15)"
					}
				},
			},
			checkBox: {
				color: 'white',
				icons: {
					checked: StatusGoodSmall
				},
				icon: {
					size: "28px",
					color: "white"
				},
				check: {
					radius: "100%",
					color: "white"
				},
				border: {
					width: "1px",
					color: "white"
				},
				hover: {
					border: {
						color: "white"
					}
				},
				extend: ({ checked }) => `
				  flex-grow: 1;
					margin: 0;
					&:hover {
						background-color: rgba(255,255,255,0.25);
					}
					${checked && `
						svg {
							fill: white;
						}
					`}
      	`
			}
		};
		var { checkedCount } = this.state;

		const { nextGraph } = this.props;
		const { prevGraph } = this.props;


		return (

			<Grommet theme={theme}>
				<Box className="filterPanel" direction="row" wrap="true">
					{clubs.map((club, index) => (
						<Box className={`filterPanel-checkbox-wrapper ${club.id === 0 && club.checked ? "activeOverviewFilter" : ""}`}

							// <Box className={"filterPanel-checkbox-wrapper"}
							basis="1/2"
							onMouseEnter={Event => (this.graphKeyMouseEnterHandler(Event))}
							onMouseLeave={Event => (this.graphKeyMouseLeaveHandler(Event))}>

							<CheckBox
								id={"fer-filterBtn-" + club.id}
								checked={club.checked}
								className={"fer-filter club-id-" + club.id}
								label={club.name}
								onChange={event => this.setState({ checked: event.target.checked }, this.setChecked(index, event, club))}
							/>
						</Box>
					))}

					{checkedCount === 3 ? <ClearButton onClickHandler={this.clearFilterClickHandler} /> : ""}
				</Box>

				<Box display="flex" direction="row" background="#6b7176">
					<Button className="filterPanel-nav-button" onClick={event => this.nextPrevClickHandler("previous")}>
						<Box pad="medium" display="flex" direction="row" justify="center" align="center">
							<LinkPrevious size={"14px"} color="white" />
							<Text margin={{ left: "6px" }} size="12px" color="white">{prevGraph}</Text>
						</Box>
					</Button>

					<Button className="filterPanel-nav-button" onClick={event => this.nextPrevClickHandler("next")}>
						<Box pad="medium" display="flex" direction="row" justify="center" align="center">
							<Text margin={{ right: "6px" }} size="12px" color="white">{nextGraph}</Text>
							<LinkNext size={"14px"} color="white" />
						</Box>
					</Button>
				</Box>
			</Grommet>

		);
	}
}
export default ClubFilterButton;