import React from "react";
import { Box, Anchor, Text } from 'grommet';
import { selectAllClubs } from "../Utils";

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navType: "graphs",
            navData: null
        }
        this.state.navType = props.navType;
        this.useHrefInsteadOfClicks = false;
        this.state.navData = props.graphNav;
    }
    getGraphsNav() {

        var hrefsOrClicks = this.useHrefInsteadOfClicks;
        let { navData } = this.state;
        return (
            hrefsOrClicks === true ?
                navData.map(graph => graph.hide ? null : (
                    <Anchor className={graph.isActive ? "is-active" : "inactive"} href={"detail?graph=" + graph.url} primary alignSelf={"center"} margin={{ left: "18px" }}>
                        <Text size="12px">{graph.title}</Text>
                    </Anchor>
                )) :
                navData.map(graph => graph.hide ? null : (
                    <Anchor primary className={graph.isActive ? "is-active" : ""} alignSelf={"center"} margin={{ left: "18px" }} onClick={(event) => { this.props.navClickHandler(graph.id, navData) }}>
                        <Text size="12px">{graph.title}</Text>
                    </Anchor>
                ))

        )
    }
    getClubsNav() {
        var clubs = selectAllClubs();
        return (
            clubs.map(club => (
                <Anchor id={"nav-club-id-" + club.id} href="#" primary alignSelf={"center"} margin={{ left: "18px" }} onClick={this.props.navClickHandler}>
                    <Text size="12px">{club.name}</Text>
                </Anchor>
            ))
        )
    }
    render() {

        return (
            <Box tag="nav" direction={"row"} fill={"horizontal"} justify={"end"}>
                {
                    this.state.navType === "clubs" ? "" : this.getGraphsNav()
                }
            </Box>
        )
    }
}
export default Navigation;