import React, { Fragment } from "react";
import { Box, Text, Heading } from 'grommet';
import CircleChart from './CircleChart';
import LinearChart from "./LinearChart";
import HalfCircleChart from "./HalfCircleChart";
import { getClubById, getGraphById, getYearGraphData } from "../Utils";
import MarketShareNavigation from "./MarketShareNavigation";
import LinearChartSup from "./LinearChartSup";
import LinearChartToggle from "./LinearChartToggle";
import { withRouter } from 'react-router-dom';

class MarketView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedClubs: [],
			currentGraph: '',
			currentPeriod: "2023",
			previousPeriod: "2022",
			clickedClubId: 1
		}
		this.state.selectedClubs = getClubById(1);
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.selectedClubs.length > 0) {
			this.state.selectedClubs = nextProps.selectedClubs
		}
		this.state.currentGraph = nextProps.currentGraph;

	}
	getGraphType(graphType, graphData, i) {

		var element = '';
		switch (graphType) {
			case "halfcircle":
				 element = <HalfCircleChart keykey={`fer-halfCircle-${i}`} showKey={this.props.showKeys} graphData={graphData} keyMouseEnterHandler={this.graphMouseEnterHandler} keyMouseLeaveHandler={this.graphMouseLeaveHandler} />
				break;
			case "linear":
				element = <LinearChart key={`fer-linearChart-${i}`} data={graphData} />
				break;
			case "linear-xaxis":
				element = <LinearChartSup key={`fer-linearChartSup-${i}`} data={graphData} />

				break;
			case "linear-toggle":
				element = <LinearChartToggle key={`fer-linearChartSup-${i}`} data={graphData} />

				break;
			default:
				element = <CircleChart key={`fer-concentricCircle-${i}`} showKey={this.props.showKeys} currentGraphData={graphData} keyMouseEnterHandler={this.graphMouseEnterHandler} keyMouseLeaveHandler={this.graphMouseLeaveHandler} />
		}

		return (
			element
		)
	}
	handleClickRedirect(graphUrl) {
		this.props.history.push("/detail?graph=" + graphUrl)
	}
	getGraph(graph, i) {
		var graphId = graph['data'].graphId;
		var graphTypeData = getGraphById(graphId);

		if (graphTypeData[0] && graphTypeData[0].hide) {
			return '';
		}

		var graphType = graphTypeData[0].graphType;
		var graphUrl = graphTypeData[0].url;
		var currentPeriod = this.state.currentPeriod;
		var graphTitle = graphTypeData[0].title;
		var clubname = this.state.selectedClubs[0].name;
		var curGraphData = getYearGraphData(clubname, graphTitle, currentPeriod);

		var element = this.getGraphType(graphType, curGraphData, i);
		var className = "fer-club-header";
		return (
			<Box role="button" id={"graph-" + i} tag="section" key={i} justify="start" align="center" className={"fer-graphWrapper"} fill="horizontal" onClick={Event => this.handleClickRedirect(graphUrl)}>
				{
					<Box fill={"horizontal"} pad={{ bottom: "12px" }} justify={"center"} className={className}>
						<Heading margin={{ bottom: "0" }} color={"fBlack"} size="1em">{graphTitle}</Heading>
					</Box>
				}
				<Fragment>
					<Text>{this.state.showKeys ? currentPeriod : ""}</Text>
					{element}
				</Fragment>
			</Box >
		)
	}
	getGrid() {
		return (
			<Box className={"fer-gridWrapper"} direction="row" wrap={true}>
				{

					this.state.selectedClubs[0].graphs.map((graph, i) => (
						<Fragment key={`fer-fragment-${i}`}>
							{this.getGraph(graph, i)}
						</Fragment>
					))

				}
			</Box>
		)

	}
	getRow() {
		var selectedClubs = this.state.selectedClubs.filter((club) => club.checked === true);
		var single = selectedClubs.length == 1;
		// console.log(single);
		return (
			<Box className={"fer-rowWrapper"} flex="grow" direction="row">
				{
					selectedClubs.map((club, i) => (
						this.getGraph(club, i, single)
					))
				}
			</Box>
		)
	}
	setCurrentlyClickedClubId = (eventType, club) => {
		if (this.state.clickedClubId === null || this.state.clickedClubId !== club.id || eventType === "mouseenter") {
			this.state.clickedClubId = club.id
			this.state.selectedClubs = getClubById(club.id);
		} else if (this.state.clickedClubId === club.id && eventType === "mouseleave") {
			this.state.clickedClubId = null;
		}
		this.setState(this.state)
	}
	render() {
		const { clickedClubId } = this.state;
		return (
			<Fragment>

				<Box direction={"column"} width={"100%"} className={"fer-graphsWrapper"}>
					<Box background="#f9fafb" direction={"row"} width={"100%"} align="center" fill={"horizontal"} pad={"none"} justify="between">
						<h1>
							<img width="250px" className="market-text" src={require(".././assets/update/2212/2023_Navigation.svg")} alt="P&amp;I Market Review 2023" />
						</h1>
						<Box tag="nav" pad={{ left: "0", right: "24px", top: "0", bottom: "0" }} flex="grow" align="center" direction="row" justify="center" width={{ max: "55rem" }}>
							<MarketShareNavigation key={`fer-marketShareNav-1`} mouseEnterHandler={this.setCurrentlyClickedClubId} mouseLeaveHandler={this.setCurrentlyClickedClubId} clickedClubId={clickedClubId} />
						</Box>
					</Box>

					{
						this.getGrid()
					}
				</Box>
			</Fragment >
		)
	}


}
export default withRouter (MarketView);