import React from 'react';
import Splash from '.././Elements/Splash';
import { withRouter } from 'react-router-dom';
import { isBrowserSupported} from "../Utils";

class Home extends React.Component {
	componentWillMount() {
		if(isBrowserSupported()){
			setTimeout(() => {
				this.props.history.push('/main')
			}, 3500);
		}
		else{
			this.props.history.push('/unsupported-browser')
		}
	}

	render() {
		return (
			<Splash />
		)
	}
}

export default Home; // withRouter(Home);