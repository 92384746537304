import React, { Fragment } from "react";
import { Box, Text, Heading } from 'grommet';
import data from '.././dataModel';
import CircleChart from './CircleChart';
import LinearChart from "./LinearChart";
import HalfCircleChart from "./HalfCircleChart";
import {getYearGraphData, getCustomKey, sortById} from "../Utils";
import LinearChartSup from "./LinearChartSup";
import LinearChartToggle from "./LinearChartToggle";
import Key from "./Key";
import PanelHeading from "./PanelHeading";
import ToggleYear from ".././Elements/ToggleYear";

class GraphView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedClubs: [],
			currentGraph: '',
			currentPeriod: "2023",
			previousPeriod: "2022",
			colourToUseInHeader: "initial",
			renderedGraphs: 0,
			showPreviousYearGraphData: null,
			isMarketShareView: false
		}
		this.state.isMarketShareView = props.isMarketShareView;
		this.state.selectedClubs = props.selectedClubs;
		this.state.currentGraph = props.currentGraph;
		this.state.colourToUseInHeader = props.selectedColourToUseInHeader;
		this.state.graphsToDraw = props.selectedClubs.length;

		if (!this.isOverview()) {
			this.state.selectedClubs = props.selectedClubs;
		}
		this.state.graphsToDraw = this.state.selectedClubs.length;
		this.graphMouseEnterHandler = this.props.keyMouseEnterHandler;
		this.graphMouseLeaveHandler = this.props.keyMouseLeaveHandler;
		this.previousYearClickHandler = props.previousYearClickHandler;
		this.state.showPreviousYearGraphData = props.showPreviousYearGraphData;
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.state.graphName = prevProps.graphName;
		this.state.selectedClubs = prevProps.selectedClubs;
	}


	getGraphDescription(graphName) {
		var description = "";

		data.graphs.forEach((graph) => {
			if (graph.title.indexOf(graphName) === 0) {
				description = graph.description;
			}
		})
		return description;
	}
	isOverview() {
		return this.props.isOverview;
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.selectedClubs.length > 0) {
			this.state.selectedClubs = nextProps.selectedClubs
		}
		this.state.currentGraph = nextProps.currentGraph;

	}
	getAllClubs(data) {
		var clubNamesArr = [];
		data.clubs.map(club => {
			clubNamesArr.push(club.name);
			return club;
		})
		return clubNamesArr;
	}
	getGraphType(graphData, club, index) {
		var numberFormat = typeof this.state.currentGraph[0]['formatting'] != "undefined" ? this.state.currentGraph[0]['formatting'] : "percent";
		var element = '';
		var clubId  = club.id;
		var shouldShowKey = club["shouldShowKey"];
		if(this.state.currentGraph[0].graphType === "linear" && !this.isOverview()){
			shouldShowKey = true;

		}
		console.log(this.state.currentGraph[0]);
		var customKey = this.state.currentGraph[0].title === "Geographical Spread" ? getCustomKey() : "";
		switch (this.state.currentGraph[0].graphType) {

			case "halfcircle":
				element = <HalfCircleChart showValues showKey={shouldShowKey} graphData={graphData.currentPeriod} keyMouseEnterHandler={this.graphMouseEnterHandler} keyMouseLeaveHandler={this.graphMouseLeaveHandler} />
				break;
			case "linear":
				element = <LinearChart showKey={shouldShowKey}  data={graphData.currentPeriod} showOnlyFirstKey={true}/>
				break;
			case "linear-xaxis":
				element = <LinearChartSup showKey={shouldShowKey} data={graphData.currentPeriod} />
				break;
			case "linear-toggle":
				element = <LinearChartToggle showKey={shouldShowKey} data={graphData.currentPeriod} clubData={this.state.currentGraph[0]} />
				break;
			default:
				element =
					<CircleChart
						showPrevYear
						showValues
						clipCurrentYear={false}
						showKey={shouldShowKey}
						previousGraphData={graphData.previousPeriod}
						currentGraphData={graphData.currentPeriod}
						keyMouseEnterHandler={this.graphMouseEnterHandler}
						keyMouseLeaveHandler={this.graphMouseLeaveHandler}
						clubId={clubId}
						index={index}
						previousYearClickHandler={this.previousYearClickHandler}
						showPreviousYearGraphData={this.props.showPreviousYearGraphData}
						isOverview={this.isOverview()}
						numberFormat={numberFormat}
						customKey={customKey}
					/>
		}
		this.state.renderedGraphs++;
		return (
			element
		)
	}
	isLinear() {
		var isLinear = false;
		if (this.state.currentGraph[0].graphType === "linear") {
			isLinear = true;
		}
		return isLinear;
	}
	getGraphData(club, currentPeriod, previousPeriod) {
		let graphData = {};
		if (Array.isArray(club) && this.isLinear() && !this.isOverview()) {
			var combinedData = [];
			let selectedClubsDataTmp = JSON.parse(JSON.stringify(club));
			club = club[0];
			graphData.currentPeriod = getYearGraphData(club.name, this.state.currentGraph[0].title, currentPeriod);

			graphData.previousPeriod = getYearGraphData(club.name, this.state.currentGraph[0].title, previousPeriod);
			let tmpGraphData = JSON.parse(JSON.stringify(graphData));

			selectedClubsDataTmp.map(clubData => {
				var data = JSON.parse(JSON.stringify(getYearGraphData(clubData.name, this.state.currentGraph[0].title, currentPeriod)));
				data.colour = clubData.colour;
				combinedData.push(data);
				return clubData;
			})
			if (combinedData.length >= 1) {
				for (var j = 0; j < combinedData.length; j++) {
					combinedData[j][1].colour = combinedData[j].colour;
					tmpGraphData.currentPeriod.push(combinedData[j][1]);
				}
			}
			return tmpGraphData;
		}
		else {
			graphData.currentPeriod = JSON.parse(JSON.stringify(getYearGraphData(club.name, this.state.currentGraph[0].title, currentPeriod)));
			graphData.previousPeriod = JSON.parse(JSON.stringify(getYearGraphData(club.name, this.state.currentGraph[0].title, previousPeriod)));
		}

		return graphData;
	}
	toggle(toggleReport) {
		
	}
	onClickSelectClub(clickedElement) {
		if (document.getElementsByClassName("fer-overview").length) {
			let idToClick = `fer-filterBtn-${clickedElement.id}`;
			document.getElementById(idToClick).click();
		}
	}
	getGraph(club, i, single=false) {
		var { currentPeriod, previousPeriod } = this.state;
		let graphData = this.getGraphData(club, currentPeriod, previousPeriod);

		var headerId = "club" + club.id
		var colourToUseInHeader = club.colour ? club.colour : "fBlue";
		var clubName = typeof club.name != "undefined" ? club.name : club[0].name;
		var isTonnage = this.state.currentGraph[0].graphType === "linear" ;
		return (


			<Box role={this.isOverview() ? 'button' : ''} key={i} id={"fer-graph-club-" + club.id} tag="section" width={{ min: "33.3 %" }} justify="start" align="center" className={"fer-graphWrapper"} fill="horizontal" onClick={() => this.onClickSelectClub(club)}>
				{this.isOverview() && <img className={"fer-graphWrapper-logo"} src={require(".././assets/clubLogos/" + club.logoFile)} alt="North Logo" />}
				{
					<PanelHeading isTonnage={isTonnage} clubName={clubName} isOverview={this.isOverview()} graphType={this.state.currentGraph[0]} headerId={headerId} selectedClubs={sortById(this.state.selectedClubs,"addedOrder")} colourToUseInHeader={colourToUseInHeader} />


					/*!this.isOverview() ? <Box className="fer-graphWrapper-banner" background={colourToUseInHeader} id={headerId} fill={"horizontal"} pad={"medium"} flex={{ shrink: 0 }} height={"60px"} justify={"center"}>
						<Heading margin={{ bottom: "0" }} color="inherit" size="1em">{clubName}</Heading>

					</Box> : ''*/
				}

				<ToggleYear isOverview={this.isOverview()} className="toggle-year" toggleYear={this.props.toggleYear} graphNav={this.props.graphNav} />

				<Fragment>
					<Text>{this.state.showKeys ? currentPeriod : ""}</Text>
					<div>{this.state.currentGraph[0].toggle && single ? <div><a onClick={() => this.toggle(this.state.currentGraph[0].toggle)} id="switch_policy_year">Policy Year</a><a onClick={() => this.toggle(this.state.currentGraph[0].toggle)} id="switch_financial_year">Financial Year</a></div> : ""}</div>
					{this.getGraphType(graphData, club, i)}
				</Fragment>

			</Box >
		)
	}
	getGrid() {
		const { selectedClubs, isMarketShareView } = this.state;
		var currentGraph = this.state.currentGraph;
		return (
			<Box className={"fer-gridWrapper"} direction="row" wrap="true">
				{isMarketShareView ? "" : selectedClubs.map((club, i) => (this.getGraph(club, i)))}
				<Key graphData={currentGraph} />
			</Box>
		)

	}
	getRow() {
		this.state.renderedGraphs = 0;
		var selectedClubs = sortById(this.state.selectedClubs.filter((club) => club.checked === true),"addedOrder");
		let selectedCountClassName = "fer-selectedCount-" + selectedClubs.length;

		return (
			<Box className={`fer-rowWrapper ${selectedCountClassName}`} flex="grow" direction="row">
				{
					this.isLinear() ? this.getGraph(selectedClubs) : selectedClubs.map((club, i) => (this.getGraph(club, i)))
				}
			</Box>
		)
	}
	render() {
		let showGraphDesc = !this.isOverview();
		const toDisplay = this.isOverview() ? this.getGrid() : this.getRow();
		const graphName = this.state.currentGraph[0].description;
		return (
			<Box direction={"column"} width={"100%"} className={"fer-graphsWrapper"}>
				{showGraphDesc &&
					<Box background="#f9fafb" height={{ min: "60px", max: "60px" }} align="center" pad="medium" direction={"row"} width={"100%"}>
						<Heading margin={{ bottom: "0" }} color={"fBlack"} size="1em">
							{graphName}</Heading></Box>
				}
				{toDisplay}
			</Box>
		)
	}


}
export default GraphView;