import React, { Fragment } from 'react';
import { Box, Heading } from 'grommet';
import Header from '.././Elements/Header';
import GraphView from '.././Elements/GraphView';
import BodyClassSetter from '../Elements/BodyClassSetter';
import ClubFilter from "../Elements/ClubFilter";
import Footer from "../Elements/Footer";
import data from '.././dataModel';
import {
    selectAllClubs, getGraphById, getGraphCount, getPrevNextGraphName, getGraphByUrl, getClubByUrl,
    toggleActiveInactiveClassOnFilterButtonHover, setNavElementAsActive, resetNavGraphObject,
    prepareNavGraphsObject, sortById, toggleGraphObject
} from "../Utils";

class DetailView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOverview: true,
            selectedClubs: selectAllClubs(),
            currentGraph: { name: "", index: "" },
            filterCount: 0,
            filterState: [],
            showKeys: true,
            nextGraph: null,
            prevGraph: null,
            urlParams: null,
            showPreviousYearGraphData: false,
            graphNav: null
        }
        this.data = data;
        this.state.urlParams = this.getGraphClubFromUrl();
        this.state.currentGraph = this.getGraphData();
        this.state.nextGraph = getPrevNextGraphName(this.state.currentGraph[0].id, 1);
        this.state.prevGraph = getPrevNextGraphName(this.state.currentGraph[0].id, 0);
        this.state.graphNav = setNavElementAsActive(this.state.currentGraph[0].id, prepareNavGraphsObject());
    }

    getGraphView(selectedClubs, graphName) {
        return (<GraphView selectedClubs={selectedClubs} graphName={graphName} />)
    }
    componentDidMount() {
        this.setClub();
    }

    getFilterData = (filterData) => {
        this.state.filterState = filterData;
        this.state.selectedClubs = this.setShouldShowKey(filterData.clubs.filter((clubs) => (clubs.checked === true)))

        if (this.state.selectedClubs[0].name.indexOf("Overview") === 0 && this.state.selectedClubs[0].checked) {
            this.state.selectedClubs = selectAllClubs();
        }
        // this.state.selectedClubs = filterData.clubs.filter((clubs)=>(clubs.checked === true));
        this.state.isOverview = filterData.isOverview;
        if (this.state.isOverview) {
            this.state.showPreviousYearGraphData = false;
        }
        this.forceUpdate();
    }


    /**
     * set key and 2018 label always in first panel to the left
     * @param filter
     */
    setShouldShowKey(filter) {
        sortById(filter,"addedOrder").forEach(item => (
            item.shouldShowKey = false
        ))
        filter[0].shouldShowKey = true;
        console.log(filter);
        return filter;
    }
    previousYearClickHandler = (clicked, index) => {
        this.state.showPreviousYearGraphData = clicked;
        // workaround to lift up previous year on all already visible graphs, without it if there are say 3 visible
        // and you click on show previous year, only first one will be updated
        // however when you have only 1 shown with selected previous year, all new will come with correctly rendered graphs
        if (index === 0) {
            let clickedElements = document.getElementsByClassName("overlayButton");
            for (var i = 1; i < clickedElements.length; i++) {
                clickedElements[i].click();
            }
        }
    }
    isOverview() {
        return this.state.isOverview;
    }
    navClickHandler = (clickedGraphId, navElements) => {
        this.state.currentGraph = getGraphById(clickedGraphId);
        this.state.graphNav = setNavElementAsActive(clickedGraphId, resetNavGraphObject(navElements));
        this.forceUpdate()
    }
    toggleYear = (clickedGraphId, navElements) => {
        this.state.currentGraph = getGraphById(clickedGraphId);
        toggleGraphObject(navElements).map(item => {
            if (item.id === clickedGraphId) {
                item.toggleActive = true;
            }
        })

        this.forceUpdate()
    }
    graphKeyMouseEnterHandler(hoveredElement) {
        toggleActiveInactiveClassOnFilterButtonHover(hoveredElement, 1);
    }
    graphKeyMouseLeaveHandler(hoveredElement) {
        toggleActiveInactiveClassOnFilterButtonHover(hoveredElement, 0);
    }
    getClassBasedByState() {
        var className = "fer-detailView";
        if (this.state.isOverview) {
            className = "fer-overview";
        }
        return className;
    }
    nextPrevButtonsClickHandler = (action) => {
        var graphsCount = getGraphCount();
        var prevAction = "previous";
        var currId = this.state.currentGraph[0].id;
        var nextId = null;
        if (action === prevAction) {
            if (currId === 1) {
                nextId = graphsCount;
            } else {
                nextId = currId - 1;
            }
        } else {
            if (currId === graphsCount) {
                nextId = 1;
            } else {
                nextId = currId + 1;
            }
        }
        this.state.currentGraph = getGraphById(nextId);
        resetNavGraphObject(this.state.graphNav);
        this.state.currentGraph[0].isActive = true;
        this.forceUpdate(); //todo:: how to avoid this??
        this.state.nextGraph = getPrevNextGraphName(this.state.currentGraph[0].id, 1);
        this.state.prevGraph = getPrevNextGraphName(this.state.currentGraph[0].id, 0);
    }

    getGraphClubFromUrl() {
        var urlParams = new URLSearchParams(window.location.search);
        return {
            graph: urlParams.get('graph'),
            club: urlParams.get('clubname')
        }

    }
    getGraphData() {
        var graphData = getGraphByUrl(this.state.urlParams.graph);
        if (graphData.length === 0) {
            graphData = getGraphById(6)
        }
        return graphData;
    }
    render() {
        const { selectedClubs, currentGraph, graphNav } = this.state;
        var nextGraph = this.state.nextGraph;
        var prevGraph = this.state.prevGraph;
        var graphTitle = currentGraph[0].description;
        const { isOverview } = this.state;

        var selectedColour = typeof this.state.filterState.colourToUse != "undefined" ? this.state.filterState.colourToUse : "";
        return (
            <BodyClassSetter customClass={this.getClassBasedByState()} responsive={true}>
                <Header includeNav='1' navClickHandler={this.navClickHandler} graphNav={graphNav} />
                <Box tag="main" direction={"row"}>
                    <Box className="fer-mainView" direction={"column"} width={"100%"}>
                        {isOverview ?
                            <Fragment>
                                <Box className="fer-overview-banner-animation"></Box>
                                <Box className="fer-overview-banner" background="#f9fafb" pad={"medium"} justify="center" height="60px" width={"100%"}>
                                    <Heading margin={{ bottom: "0" }} color={"fBlack"} size="1em">{graphTitle}</Heading>
                                </Box>
                            </Fragment> : ""}
                        <GraphView
                            selectedClubs={selectedClubs}
                            currentGraph={currentGraph}
                            isOverview={this.state.isOverview}
                            showKeys={this.stateShowKeys}
                            keyMouseEnterHandler={this.graphKeyMouseEnterHandler}
                            keyMouseLeaveHandler={this.graphKeyMouseLeaveHandler}
                            selectedColourToUseInHeader={selectedColour}
                            previousYearClickHandler={this.previousYearClickHandler}
                            navClickHandler={this.navClickHandler}
                            toggleYear={this.toggleYear}
                            graphNav={graphNav}
                            showPreviousYearGraphData={this.state.showPreviousYearGraphData}
                        />
                    </Box>
                    <Box tag="form" className="filterPanel-wrapper" pad="none" background={"fGrey800"}>
                        <ClubFilter
                            clubs={data.clubs}
                            filterGetter={this.getFilterData}
                            nextPrevClickHandler={this.nextPrevButtonsClickHandler}
                            currentGraph={currentGraph}
                            nextGraph={nextGraph}
                            prevGraph={prevGraph}
                            graphKeyMouseEnterHandler={this.graphKeyMouseEnterHandler}
                            graphKeyMouseLeaveHandler={this.graphKeyMouseLeaveHandler}
                        />
                    </Box>
                </Box>
                <Footer />
            </BodyClassSetter>
        )
    }


    setClub() {
        // todo: change it at some point so it doesn't do it by click
        var clubUrl = this.state.urlParams.club;
        if (clubUrl) {
            var club = getClubByUrl(clubUrl);
            var filterBtn = document.getElementById("fer-filterBtn-" + club[0].id);
            filterBtn.click();
        }
    }


}

export default DetailView;