import React from "react";
import * as d3 from "d3";
import "./../Chart.css";
import "./../Spinner.css";
import LinearChartLine from "./LinearChartLine";
import {Text} from 'grommet';

const LinearChartToggle = ({ data, showKey, clubData }) => {
  //Graph heights and widths etc

  const SVGHeight = 400;
  const SVGWidth = 600;
  const MARGIN = 50;
  const graphHeight = SVGHeight - 2 * MARGIN;
  const graphWidth = SVGWidth - 2 * MARGIN;

  const combinedItems = data.reduce((acc, currentValue) => [...acc, ...currentValue.items], [])
  console.log(clubData)
  //D3 pos
  const xDomain = [...new Set(combinedItems.map(item => item.label))];
  // const xDomain = combinedItems.map(item => item.label);

  const x = d3
    .scalePoint()
    .domain(xDomain)
    .range([0, graphWidth])

  const yDomain = d3.extent([...new Set(combinedItems.map(item => item.value))]);
  const y = d3
    .scaleLinear()
    .domain(yDomain)
    .range([-graphHeight / 4, -graphHeight / 2])

  return (

    <div className="chart-wrapper linear">
      {
        showKey &&
          <ul className="chart-key linear linearToggle">

            {<li className="chart-key-item" onClick={alert()}>
              <svg className="chart-key-svg" viewBox="0 0 10 10">
                <circle cx="5" cy="5" r="3" fill={clubData.toggleColor1} />
              </svg>
              {(clubData.toggleTitle1)}

            </li>
            }
            {
              <li className="chart-key-item">
              <svg className="chart-key-svg" viewBox="0 0 10 10">
              <circle cx="5" cy="5" r="3" fill={clubData.toggleColor2} />
              </svg>
            {(clubData.toggleTitle2)}

              </li>
            }
          </ul>
      }
      <svg className="chart-svg linear" viewBox={`0 0 ${SVGWidth} ${SVGHeight}`}>
        <g transform="translate(50 350)">
          <line
            x1={-20}
            x2={graphWidth + 20}
            y1={y(0)}
            y2={y(0)}
            stroke="#AAA"
            strokeWidth="1"
            strokeDasharray="2"
          />

          <text
            textAnchor="middle"
            y={y(0 + graphHeight)}
            x={graphWidth + 30}
          >
            0
        </text>

          {/* Axes */}
          {combinedItems.map((item, i) => (
            <g key={i}>

              <line
                className="linear-line"
                key={"item" + i}
                x1={x(item.label)}
                x2={x(item.label)}
                y1={0}
                y2={-graphHeight}
                stroke="#AAA"
                strokeWidth="1"
                strokeDasharray="2"
              />
              <text x={x(item.label)} y={30} textAnchor="middle">
                {item.label}
              </text>
              }
      </g>
          ))}

          {/* <path d={Area(items)} fill="blanchedalmond" opacity="0.75" strokeWidth="2" /> */}
          {data.map((singleClub,i) => <LinearChartLine key={i} colour={singleClub.colour} items={singleClub.items} x={x} y={y} />)}
        </g>
      </svg >

      {showKey &&
        <ul className="chart-key linear linearXAxis">
          {data.map((singleClub, i) => <li key={i} className="chart-key-item">
            <svg className="chart-key-svg" viewBox="0 0 10 10">
              <circle cx="5" cy="5" r="3" fill={singleClub.colour} />
            </svg>
            {(singleClub.type && singleClub.type) || "Not defined"}
            {i > 0 ? <Text size="12px"> (in Thousands)</Text> : ""}
          </li>)}
        </ul>
      }
    </div>
  );
};


export default (LinearChartToggle);