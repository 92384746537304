import React from "react";
import { Box, Anchor, Text } from 'grommet';
import "../ToggleYear.css"

class ToggleYear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navType: "graphs",
            navData: null
        }
        this.state.navData = props.graphNav;
    }
    getGraphsNav() {

        let { navData } = this.state;
        let toggleId = "",
            graphId = "";

        let result = [];

        navData.map(graph => {
            if (!graph.isActive || !graph.toggle) return null;
            toggleId = graph.toggle;
            graphId = graph.id;
        })

        navData = navData.filter(graph => graph.id === graphId || graph.id === toggleId);


        for (let i = 0; i < navData.length; i++) {
            const graph = navData[i];
            if (graph.id !== graphId && graph.id !== toggleId) continue;
            result.push(
                <Anchor primary className={graph.toggleActive ? "is-active" : ""} alignSelf={"center"} margin={{ left: "18px" }} onClick={(event) => { this.props.toggleYear(graph.id, navData) }}>
                    <Text size="12px">
                        {i === 0 ? 'Policy year' : (i === 1 ? 'Financial year' : graph.title)}
                    </Text>
                </Anchor>
            );
        }

        return (
            result
        )
    }
    render() {
        if (!this.props.isOverview) {
            return (
                <Box tag="div" className="toggle-year" direction={"row"} margin={{ top: "10px", right: "30px" }} fill={"horizontal"} justify={"end"}>
                    {
                        this.getGraphsNav()
                    }
                </Box>
            )
        }
        else return ""
    }
}
export default ToggleYear;