import React from "react";
import { Box, Heading } from 'grommet';

class PanelHeading extends React.Component {
    render() {
        const { selectedClubs, isTonnage, clubName, colourToUseInHeader, headerId } = this.props;
        return (

            !this.props.isOverview && isTonnage ?
                <Box className="fer-graphWrapper-banner-wrapper" display="flex" direction="row" alignSelf="start" width="100%" flex="1">
                    {selectedClubs.map((item, index) => (
                        <Box className="fer-graphWrapper-banner inline" background={selectedClubs[index].colour} id={headerId}
                            fill={"horizontal"} pad={"medium"} height={"60px"} justify={"center"} flex="1">
                            <Heading margin={{ bottom: "0" }} color="inherit" size="1em">{selectedClubs[index].name}</Heading>

                        </Box>
                    ))}                </Box>

                : !this.props.isOverview && !isTonnage ?
                    <Box className="fer-graphWrapper-banner panel" background={colourToUseInHeader} id={headerId}
                        fill={"horizontal"} pad={"medium"} flex={{ shrink: 0 }} height={"60px"} justify={"center"}>
                        <Heading margin={{ bottom: "0" }} color="inherit" size="1em">{clubName}</Heading>
                    </Box>
                    : ""
        )
    }
}

export default PanelHeading;
