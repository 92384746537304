import React from "react";
import { Box, Text, Heading } from 'grommet';
import { getClubById, getYearGraphData, toggleKeyHover } from "../Utils";


class Key extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentGraph: props.graphData,
            graphKeys: null
        }
        this.state.graphKeys = this.getGraphKeys();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        this.state.currentGraph = nextProps.graphData;
        this.state.graphKeys = this.getGraphKeys();
        this.forceUpdate();
    }
    getGraphKeys() {
        var graphId = this.state.currentGraph[0].id;
        // if (graphId == 9) {
        //     graphId = 5;
        // }

        //get first club and fetch keys from it for graph with matching id
        var club = getClubById(1);
        var graphData = getYearGraphData(club[0].name, this.state.currentGraph[0].title, "2023");
        if (!(graphId === 5 || graphId === 3)) {
            graphData = graphData["items"];
        }

        if (!graphData) {
            return [];
        }
        
        return graphData;
    }
    getClass = (graphData) => {
        var color = graphData.color;
        var className = "";
        if (typeof color != "undefined") {
            className = color.substr(1);
        }

        return className.toString();
    }
    highlightByColour = (event) => {
        var id = event.target.id;
        var circles = document.getElementsByClassName("color-#" + id);
        this.toggleClass(circles, "is-active");
    }
    toggleClass = (elements, className) => {
        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.toggle(className);
        }
    }

    graphKeyMouseEnterHandler(hoveredElement) {
        toggleKeyHover(hoveredElement, 1);
    }
    graphKeyMouseLeaveHandler(hoveredElement) {
        toggleKeyHover(hoveredElement, 0);
    }


    render() {
        const { graphKeys } = this.state;
        let showText = this.state.currentGraph[0].graphType === "concentric" ? true : false;
        return (
            <Box className="overview-key-wrapper" tag="aside" flex="grow" pad="medium" justify="center"
                onMouseEnter={this.graphKeyMouseEnterHandler}
                onMouseLeave={this.graphKeyMouseLeaveHandler}>
                <Heading level="2" color={"fBlack"} size="1em">Key</Heading>
                {
                    showText ? <Text size="12px">Select to highlight data</Text> : ""
                }
                <ul className="chart-key overview-key">
                    {graphKeys.map((graph, i) => (
                        <li className={"chart-key-item "} id={this.getClass(graph)} key={i} onMouseEnter={(event) => this.highlightByColour(event)} onMouseLeave={(event) => this.highlightByColour(event)}>
                            <svg className="chart-key-svg" viewBox="0 0 10 10">
                                <circle cx="5" cy="5" r="3" fill={graph.color} />
                                <circle className="chart-key-svg-ring" cx="5" cy="5" r="4" fill="none" stroke={graph.color} strokeWidth="0.5" />
                            </svg>
                            {graph.title}
                            {i > 0 && this.state.currentGraph[0].title == "Underwriting Results" ? <Text size="12px"> (in Thousands)</Text> : ""}
                        </li>
                    ))}
                </ul>

            </Box>
        )
    }
}
export default Key;