import React, { Fragment } from "react";
import { Box, Text, Heading } from 'grommet';
import ClubFilterButton from "./ClubFilterButton";

class ClubFilter extends React.Component {
	constructor(props) {
		super(props);
		this.elementTitle = "Filter";
		this.elementDescription = "Select up to 3 Clubs";
		this.clubs = props.clubs;
		this.state = {
			selectedClubs: 0
		}
		this.nextPrevClickHandler = props.nextPrevClickHandler;
		this.currentGraph = props.currentGraph;

		this.nextGraph = props.nextGraph;
		this.prevGraph = props.prevGraph;
		this.graphKeyMouseEnterHandler = props.graphKeyMouseEnterHandler;
		this.graphKeyMouseLeaveHandler = props.graphKeyMouseLeaveHandler;
	}
	componentWillReceiveProps(nextProps, nextContext) {
		this.nextGraph = nextProps.nextGraph;
		this.prevGraph = nextProps.prevGraph;
	}
	render() {
		var nextGraph = this.nextGraph;
		var prevGraph = this.prevGraph;

		return (
			<Fragment>
				<Box background={"fBlack"} pad={"medium"} height={"60px"} justify={"center"} flex={{ shrink: 0 }}>
					<Heading level="2" margin={{ bottom: "0" }} size="1em">{this.elementTitle}</Heading>
					<Text size="14px" margin={{ top: '6px' }} color={"#AAA"}>{this.elementDescription}</Text>
				</Box>
				<Box pad={"none"} direction={"column"}>
					<ClubFilterButton
						clubs={this.clubs}
						filterGetter={this.props.filterGetter}
						nextPrevClickHandler={this.nextPrevClickHandler}
						currentGraph={this.currentGraph}
						nextGraph={nextGraph}
						prevGraph={prevGraph}
						graphKeyMouseEnterHandler={this.graphKeyMouseEnterHandler}
						graphKeyMouseLeaveHandler={this.graphKeyMouseLeaveHandler}

					/>
				</Box>
			</Fragment>
		)
	}
}
export default ClubFilter;