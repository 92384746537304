import React from "react";
import { Box, Text } from 'grommet';
import { Download, Twitter, LinkedinOption } from "grommet-icons";

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.tagLine = props.tagLine;
        this.includeNav = props.includeNav;

    }
    render() {
        const companyName = "©P.L Ferrari & Co.";
        return (
            <Box tag="footer" justify={"between"} align="center" className="fer-footer" pad={"medium"} direction={"row"} height={"50px"} background={"fBlack"}>
                <Box direction="row" align="center" className="download">
                    <a href={require(".././assets/download/P&I_Market Review_2023_January2024Edition.pdf")} download>
                        <Download size={"14px"} /><Text margin={{ left: "10px" }} size={"12px"} color={"white"}>Download P&amp;I Report</Text>
                    </a>
                </Box>

                <Box direction="row" align="start" justify="center">
                    

                    <Box margin={{ left: "10px" }} align="end">
                        <Text margin={{ left: "10px" }} size={"10px"} color={"white"}>{companyName}</Text>
                        <a href="https://www.pangaeacreative.co.uk/" target="_blank" rel="noopener noreferrer"><Text color={"#4D617D"} margin={{ left: "8px" }} size={"10px"}>Designed by Pangaea</Text></a>
                    </Box>
                </Box>
            </Box >
        )
    }
}
export default Footer;