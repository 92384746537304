import React from 'react';
import { Box } from 'grommet';
import Header from '.././Elements/Header';
import BodyClassSetter from '../Elements/BodyClassSetter';
import Footer from "../Elements/Footer";
import { getClubById } from "../Utils";
import MarketView from "../Elements/MarketView";


class MarketShareView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOverview: true,
            selectedClubs: [],
            graphName: "Geographical Spread",
            filterCount: 0,
            filterState: [],
            showKeys: true
        }
        this.state.selectedClubs = getClubById(1)
    }
    navClickGraphHandler = (clickedElement) => {
        this.setState({graphName: clickedElement.target.innerText});
    }
    navClickClubHandler = (clickedElement) => {
        this.state.graphName = clickedElement.target.innerText;

    }
    render() {
        const { selectedClubs} = this.state;
        return (
            <BodyClassSetter customClass={'fer-marketShareView'} responsive={true} >
                <Header tagLine navType="clubs" navClickClubHandler={this.navClickClubHandler} navClickGraphHandler={this.navClickGraphHandler} />
                <Box tag="main">
                    <MarketView selectedClubs={selectedClubs} />
                </Box>
                <Footer />
            </BodyClassSetter>
        )
    }
}

export default MarketShareView;