import React from "react";
import { Box, Button, Text } from 'grommet';
import { Close } from "grommet-icons";
// import ClubFilterButton from "./ClubFilterButton";
// import data from "../dataModel";
// import { black } from "vx-components-react/lib/colors";

class ClearButton extends React.Component {
    constructor(props) {
        super(props);
        this.clickHandler = props.onClickHandler;
    }
    render() {
        return (
            <Box className="filterPanel-checkbox-wrapper" background={"#1d1d1d"} basis="1/2">
                <Button className="filterButton-clear-button" onClick={this.clickHandler}>
                    <Box pad="medium" display="flex" direction="row" justify="start" align="center">
                        <Close size={"14px"} className="fer-closeButtonXIcon"/>
                        <Text size="12px" margin={{ left: "16px" }} color="white">Clear</Text>
                    </Box>
                </Button>
            </Box >
        )
    }
}
export default ClearButton;