import React from 'react';
import Home from './Pages/Home';
import DetailView from "./Pages/DetailView";
import UnsupportedBrowser from './Pages/UnsupportedBrowser';
import Logo from "./Elements/Logo";
import { Switch, Route } from "react-router-dom";
import { Text, Grommet } from 'grommet';
import { Download } from "grommet-icons";
import './App.css';
import './Layout.css';
import MarketShareView from "./Pages/MarketShareView";

function App() {
	return (
		<Grommet theme={theme}>
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/detail" component={DetailView} />
				{/*<Route exact path="/tempNav" component={TempNav} />*/}
				<Route exact path="/main" component={MarketShareView} />
				<Route exact path="/unsupported-browser" component={UnsupportedBrowser} />
			</Switch>

			<div id="error" class="splash error">
				<div className="mobile">
				<Logo />
				</div>
				<p className="desktop">Your browser window is too small. Please resize.</p>
				<p className="mobile">The P&amp;I Market Review has been optimised to be best viewed on desktop screens.</p>
				<a className="error-link mobile" href={require("./assets/download/P&I_Market Review_2023_January2024Edition.pdf")} download>
					<Text size="14px">Click here to download the P&amp;I Market Review PDF</Text>
					<Download className="error-icon" size={"14px"} fill={"fBlack"} />
				</a>
			</div>

		</Grommet>
	);
}
export default App;

const theme = {
	global: {
		colors: {
			brand: '#6A7076',
			fDarkGrey: '#495965',
			fLightGrey: '#F6F6F6',
			fGrey: '#5C656D',
			fGrey800: '#324049',
			fSlate3: '#e6e6e6',
			fOffWhite: '#F5F6F7',
			fBlue: '#0094C7',
			fDarkBlue: '#041e42',
			fBlack: '#000000',
			fOffBlue: '#A1ABB2'
		},
		focus: {
			border: {
				color: "#0094C7",
				outline: "#0094C7"
			}
		},
		breakpoints: {
			small: null,
			medium: null,
			large: null
		},
		font: {
			family: 'Sarabun-Regular',
			height: '18px',
			size: '16px'
		},
		size: {
			full: '100%'
		}
	},
};

